import request from '@/utils/request';

// 获取课程列表
export const courseList = params => request.post(`study/course/list`, params)

// 获取推荐课程
export const recommendCourseList = params => request.get(`study/pc/recommend/courses`, params)

// 获取课程目录列表
export const courseFolder = params => request.get(`study/course/folder/tree`, params)

//课程详情
export async function detail(params = {}) {
  return request.get(`study/course/${params.id}/detail`, params);
}
// 课程附加信息详情
export async function plusDetail(params) {
  return request.post(`study/course/plus`, params);
}

//课程章节详情
export async function getSectionDetail(params = {}) {
  return request.get(`study/course/${params.id}/${params.did}/detail`, params);
}

//最近看课人员
export async function members(params = {}) {
  return request.post(`study/course/${params.id}/members`, params);
}

//开始学习
export async function courseStartLearn(params = {}) {
  return request.post(`study/course/startlearn`, params);
}

//课程学习进度提交
export async function courseLearning(params = {}) {
  return request.post(`study/course/learning`, params);
}

//课程评论列表
export async function courseComments(params = {}) {
  return request.post(`study/course/${params.resourceId}/comments`, params);
}

//课程评论/回复
export async function courseCommentSubmit(params = {}) {
  return request.post(`study/course/${params.resourceId}/comment/submit`, params);
}

//删除课程评论/回复
export async function courseCommentDel(params = {}) {
  return request.post(`study/course/${params.resourceId}/comment/del`, params);
}

//课程评论点赞/取消点赞
export async function courseCommentLike(params = {}) {
  return request.post(`study/course/${params.resourceId}/comment/like`, params);
}

//课程收藏/取消收藏
export async function favorite(params = {}) {
  return request.post(`study/course/favorite`, params);
}

//课程点赞/取消点赞
export async function courseLike(params = {}) {
  return request.post(`study/course/like`, params);
}

//课程打分
export async function star(params = {}) {
  return request.post(`study/course/star`, params);
}

// 中交云学 同步视频获取签名
export const zjyxSing = params => request.post(`study/course/zjyx/psign`, params)

// 课程 -- 学员试卷详情
export async function coursExamPaperDetail(params = {}) {
  return request.post(`lms/course/track/exam/member/paper/detail`, params);
}

// 课程实操记录
export async function vrCoursePracticeRecord(params = {}) {
  return request.post(`study/course/${params.courseId}/vrcourse`, params);
}

// 课程详情-笔记列表
export const getCourseNotesList = (params) => {
  return request.post(`study/course/usernote/list`, params);
};
// 保存课程笔记
export const saveCourseNotes = (params) => {
  return request.post(`study/course/note/submit`, params);
};
// 删除课程笔记
export const delCourseNotes = (params) => {
  return request.post(`study/course/note/delete`, params);
};