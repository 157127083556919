import request from '@/utils/request';

// 获取项目目录
export const projectFolder = params => request.get(`study/project/folder/tree`, params)

// 获取项目列表
export const projectList = params => request.post(`study/project/list`, params)

// 获取项目详情
export const projectDetail = id => request.get(`study/project/${id}/detail`)

//项目评论点赞/取消点赞
export async function projectCommentLike(params = {}) {
  return request.post(`study/project/${params.resourceId}/comment/like`, params);
}
//删除项目评论/回复
export async function projectCommentDel(params = {}) {
  return request.post(`study/project/${params.resourceId}/comment/del`, params);
}
//项目评论/回复
export async function projectCommentSubmit(params = {}) {
  return request.post(`study/project/${params.resourceId}/comment/submit`, params);
}
//项目评论列表
export async function projectComments(params = {}) {
  return request.post(`study/project/${params.resourceId}/comments`, params);
}
//获取我的任务明细
export async function getTaskDetail(params = {}) {
  return request.get(`study/task/${params.id}/${params.did}/detail`, params);
}
//获取我的任务明细 lms
export async function getTaskDetailLms(params = {}) {
  return request.get(`lms/project/track/${params.id}/${params.did}/exam/detail`, params);
}
//获取我的任务课程章节明细
export async function getCourseSectionDetail(params = {}) {
  return request.get(`study/task/${params.id}/${params.did}/${params.sectionId}/detail`, params);
}
//开始学习 *
export async function taskStartLearn(params = {}) {
  return request.post(`study/task/startlearn`, params);
}
//任务学习
export async function taskLearning(params = {}) {
  return request.post(`study/task/learning`, params);
}

// 任务列表
export const taskList = params => request.get('study/task/list', params)
// 任务列表 -- 新
export const newTaskList = params => request.post('study/task/list/v2', params)

// 报名列表
export const signupList = params => request.post(`study/project/signup/list`, params)

// 报名
export const signup = params => request.post(`study/project/signup`, params)

// 报名取消
export const signupCancel = params => request.post(`study/project/signup/cancel`, params)

// 获取项目实施目录
export const planFolder = params => request.get(`study/plan/folder/tree`, params)

// 获取AI对练结果
export const aiSparringMemberResult = params => request.post(`lms/aisparring/track/member/result`, params)

// 获取AI对练监控图片列表
export const aiSparringMonitor = params => request.post(`lms/aisparring/track/monitor/images`, params)

// 获取账号分发列表
export const projectAccountList = params => request.post(`study/project/account/list`, params)

// 分发账号消息通知
export const accountNotice = params => request.post(`study/project/account/notice`, params)